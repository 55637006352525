// Generated by Framer (400c93f)

import { addFonts, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle from "../css/AKQxX0asg";
import * as sharedStyle1 from "../css/D96MwvuXB";

const serializationHash = "framer-IoAc3"

const variantClassNames = {fS9XwjD1O: "framer-v-r3y5zp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "fS9XwjD1O", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-r3y5zp", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fS9XwjD1O"} ref={refBinding} style={{...style}}><motion.div className={"framer-1m5i8g"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"sGviv3RHS"} style={{backgroundColor: "var(--token-fb4b67b5-91ba-4b73-b4a6-f55417ea1c0c, rgb(102, 102, 102))", opacity: 0.2}}/><motion.div className={"framer-dagh77"} layoutDependency={layoutDependency} layoutId={"eQcpEkUR1"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-spc0js"} data-styles-preset={"AKQxX0asg"}><Link href={"mailto: hi@grant.design"} motionChild nodeId={"GWnH2P2Dy"} openInNewTab scopeId={"w50Bd2kcd"} smoothScroll={false}><motion.a className={"framer-styles-preset-16mhbei"} data-styles-preset={"D96MwvuXB"}>hi@grant.design</motion.a></Link></motion.p></React.Fragment>} className={"framer-1ux7ejm"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"GWnH2P2Dy"} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-spc0js"} data-styles-preset={"AKQxX0asg"} style={{"--framer-text-alignment": "right"}}><Link href={"https://www.linkedin.com/in/grantwang31/"} motionChild nodeId={"pyo9p2jGn"} openInNewTab scopeId={"w50Bd2kcd"} smoothScroll={false}><motion.a className={"framer-styles-preset-16mhbei"} data-styles-preset={"D96MwvuXB"}>Linkedin</motion.a></Link></motion.p></React.Fragment>} className={"framer-15r86t7"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"pyo9p2jGn"} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IoAc3.framer-9wcodu, .framer-IoAc3 .framer-9wcodu { display: block; }", ".framer-IoAc3.framer-r3y5zp { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 24px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 700px; }", ".framer-IoAc3 .framer-1m5i8g { flex: none; height: 1px; overflow: visible; position: relative; width: 100%; z-index: 1; }", ".framer-IoAc3 .framer-dagh77 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: hidden; padding: 0px; position: relative; width: 100%; }", ".framer-IoAc3 .framer-1ux7ejm, .framer-IoAc3 .framer-15r86t7 { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 111px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IoAc3.framer-r3y5zp { gap: 0px; } .framer-IoAc3.framer-r3y5zp > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-IoAc3.framer-r3y5zp > :first-child { margin-top: 0px; } .framer-IoAc3.framer-r3y5zp > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 700
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const Framerw50Bd2kcd: React.ComponentType<Props> = withCSS(Component, css, "framer-IoAc3") as typeof Component;
export default Framerw50Bd2kcd;

Framerw50Bd2kcd.displayName = "Footer";

Framerw50Bd2kcd.defaultProps = {height: 46, width: 700};

addFonts(Framerw50Bd2kcd, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})